import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import injectGTM from './inject-gtm';

import App from './components/App';
import Embed from './components/Embed';

const airliftRoot = document.getElementById('airlift-root');

// get query string variable embed
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const embed = urlParams.get('embed');

if (embed) {
    ReactDOM.createRoot(airliftRoot).render(<Embed embedId={embed} />);
} else {
    injectGTM();

    ReactDOM.createRoot(airliftRoot).render(<App />);
}
